import React, { ReactNode } from 'react';
import { TestIds } from '../constants';
import { IStylableButtonHeightImperativeActions } from '../StylableButton.types';
import { classes, st } from './StylableButton.component.st.css';

const ButtonContent: React.ForwardRefRenderFunction<
  IStylableButtonHeightImperativeActions,
  {
    icon?: ReactNode;
    label?: string;
    override?: boolean;
  }
> = (props, ref) => {
  const { label, icon, override } = props;
  const labelRef = React.useRef<HTMLElement>(null);
  const iconRef = React.useRef<HTMLElement>(null);
  React.useImperativeHandle(ref, () => {
    return {
      getLabelHeight: () => labelRef.current?.offsetHeight,
      getIconHeight: () => iconRef.current?.offsetHeight,
    };
  });
  return (
    <div className={classes.container}>
      {label && (
        <span
          className={classes.label}
          data-testid={TestIds.buttonLabel}
          ref={labelRef}
        >
          {label}
        </span>
      )}
      {icon && (
        <span
          className={st(classes.icon, { override: !!override })}
          aria-hidden="true"
          data-testid={TestIds.buttonIcon}
          ref={iconRef}
        >
          {icon}
        </span>
      )}
    </div>
  );
};
export default React.forwardRef(ButtonContent);
